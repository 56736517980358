import { HttpParams } from '@angular/common/http';

export interface Pagination {
  page: number;
  size: number;
  sort: string[];
}

export const defaultPagination: Pagination = {
  page: 0,
  size: 20,
  sort: [],
}

export const createRequestOption = (req?: any): HttpParams => {
  let options: HttpParams = new HttpParams();

  if (req) {
    Object.keys(req).forEach(key => {
      if (key !== 'sort') {
        options = options.set(key, req[key]);
      }
    });

    if (req.sort) {
      req.sort.forEach((val: string) => {
        options = options.append('sort', val);
      });
    }
  }

  return options;
};

export const format = (date: Date) => (`${date.getDay()}-${date.getMonth()}-${date.getFullYear()}`);


export const compare = (e1: any, e2: any) => (e1 && e2 ? e1.id === e2.id : e1 === e2);