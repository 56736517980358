import { AccountService } from './../services/account.service';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AuthentificationGuard implements CanActivate {
    constructor(private account: AccountService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.account.isAuthentificate()) {
            return true;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }

}