import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthentificationGuard } from './admin/gards/authentification-guard.service';

const routes: Routes = [
  {
    path: 'admin',
    canActivate: [AuthentificationGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'login',
    data: {
      pageTitle: '',
      authorities: []
    },
    loadChildren: () => import('./shared/accounts/login/login.module').then(m => m.LoginModule),
  },
  {
    path: '',
    loadChildren: () => import('./main/main.module').then(m => m.MainModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
