export default {
  Header: {
    particular: "Particulier",
    professional: "Professionel",
    product: {
      title: "Produits",
      payment: {
        title: "Paiements",
        subtitle: "Paiement en ligne",
      },
      health: {
        title: "Santé",
        subtitle: "Mutuelle de santé",
      },
      api: {
        title: "API Integration",
        subtitle: `Intégration d'API`,
      },
      transfer: {
        title: "Transfert",
        subtitle: `Transfert d'argent`,
      },
      insurance: {
        title: "Assurances",
        subtitle: "Assurance",
      },
      bill: {
        title: "Bills",
        subtitle: `Paiement de factures`,
      },
    },
    about: "A propos",
    help: "Aide",
    download: "Télécharger",
  },
  Footer: {
    abstract: {
      content: `Nos produits couvrent tout l'univers des services de paiement : monnaie électronique, paiement par cartes, paiement mobile, transfert de fond, assurances, systèmes d'abonnement...`,
    },
    company: {
      title: "Entreprise",
      about: "A Propos",
      partner: "Partenaire",
      carrier: "Carrières",
    },
    product: {
      title: "Produits",
      transfer: "Transfert d'Argent",
      insurance: "Assurances",
      mutual: "Mutuelles de Santé",
      payment: "Paiement en Ligne",
    },
    resource: {
      title: "Ressources",
      api: "API sunugal",
      doc: "Documentation",
    },
    support: {
      title: "Support",
      faq: "FAQ",
      trade: "Trouver un point de vente",
      contact: "Contact",
    },
    social: "© 2021 sunuGal.co",
    legal: ["Mentions légales", "Confidentialité"],
  },
  ErrorPage: {
    title: "Page d'erreur",
    message: [
      "Désolé, mais la page que vous recherchez n'existe pas.",
      "S'il vous plait, soyez certain de taper la bonne url.",
    ],
    goHome: "Retourner à l'accueil",
  },
  Home: {
    content: {
      title: "Offrir une expérience simple",
      card1: {
        title: "Envoyer de l'argent simplement",
        subtitle:
          "Transférer de l'argent pour un bénéficiare ou vers une autre carte",
        button: "En savoir plus",
      },
      card2: {
        title: "Une assurance adaptée et moins chère",
        subtitle:
          "Particuliers, Entreprises, Marchandises, Professionnelle, Construction, etc...",
        button: "En savoir plus",
      },
      card3: {
        title: "Mutuelle familiale ouverte à tous",
        subtitle: "Mutuelle familiale ouverte à tous",
        button: "En savoir plus",
      },
      card4: {
        title: "Paiement en ligne",
        subtitle: "Paiement instantané et de manière sécurisée",
        button: "En savoir plus",
      },
      card5: {
        title: "sunuGal Club",
        subtitle: "sunuGal Club",
        button: "En savoir plus",
      },
      card6: {
        title: "Support Professionnel",
        subtitle: "Support Professionnel",
        button: "En savoir plus",
      },
      benefits: {
        title: "Avantages",
        card1: {
          title: "Démarches simples",
          subtitle: "100% en ligne ou en agence",
        },
        card2: {
          title: "Garder le Contrôle",
          subtitle: "Contrôler votre compte et vos dépenses",
        },
        card3: {
          title: "Vos voyages",
          subtitle: "Dépenser et retirer de l'argent à l'étranger",
        },
      },
    },
    how: {
      title: "Comment ça marche ?",
      card1: {
        h4: "1. Inscrivez-vous",
        p: "A partir d'une agence sunugla en fournissant vos données personnelles.",
      },
      card2: {
        h4: "2. Confirmez votre identité",
        p: "Munissez-vous d'une pièce d'itentité acceptée par nos agences.",
      },
      card3: {
        h4: "3. Découvrez votre carte sunugal",
        p: "Retirer votre carte à partir de votre agence sunugal.",
      },
      card4: {
        h4: "4. Profitez de votre compte",
        p: "Vous pouvez alimenter votre carte et commencer à l'utilisze !",
      },
      bottom:
        "Une fois alimentée, votre carte prépayée est utilisable comme une carte classique pour vos retraits et vos achats, en ligne ou chez les commerçants",
    },
    agency: {
      title: "Nos agences",
    },
  },
  Product: {
    navigation: {
      payment: {
        title: "Paiements",
        subtitle: "Paiement en ligne",
      },
      health: {
        title: "Santé",
        subtitle: "Mutuelle de santé",
      },
      api: {
        title: "API Integration",
        subtitle: `Intégration d'API`,
      },
      transfer: {
        title: "Transfert",
        subtitle: `Transfert d'argent`,
      },
      insurance: {
        title: "Ansurances",
        subtitle: "Assurance",
      },
      bill: {
        title: "Bills",
        subtitle: `Paiement de factures`,
      },
    },
    Transfer: {
      title: "Transfert d'Argent",
      content: {
        title: "Envoyer de l'argent simplement",
        cards: [
          "Transférer de l'argent vers une autre carte",
          "Envoyer de l'argent à vos proches",
        ],
        choices: {
          title: "Vous avez fait le bon choix",
          p: 'Des économies, un suivi et un service client',
          a: 'Je demande ma carte',
        },
      },
    },
    Payment: {
      title: "Paiement en ligne",
      content: {
        title: "Paiement en ligne",
        button1: "CONNECTEZ-VOUS",
        cards: [
          "Payez vos services : électricité, eau, téléphone, école, médicaments, …",
          "Paiement instantané et de manière sécurisée, possibilité de prélèvement automatique",
          "Paiement des frais d'éducation pour les élèves et étudiants.",
        ],
        choices: {
          title: "Vous avez fait le bon choix",
          p: 'Des économies, un suivi et un service client',
          a: 'Je demande ma carte',
        },
      },
    },
    Health: {
      title: "Mutuelles de Santé",
      content: {
        title: "Mutuelle familiale ouverte à tous",
        button1: "CONNECTEZ-VOUS",
        cards: [
          "Accéder aux soins et à des biens médicaux de qualité",
          "Meilleure protection sociale face aux aléas de Santé",
        ],
        choices: {
          title: "Vous avez fait le bon choix",
          item1: {
            h5: "Accès à tous",
            p: "Toutes catégories socio-professionnelles, des garanties Santé adaptées à chaque situation.",
          },
          item2: {
            h5: "Meilleurs protections",
            p: "Votre santé est précieuse, elle mérite la meilleure des couvertures, qualité et performance.",
          },
          item3: {
            h5: "Service Client",
            p: "Contactez-nous ou visitez notre FAQ pour obtenir les réponses à vos questions.",
          },
          buttons: ["ENVOYER DE L'ARGENT", "JE DEMANDE MA CARTE"],
        },
      },
    },
    Insurance: {
      title: "Assurances",
      content: {
        title: "Une assurance adaptée et moins chère",
        button1: "CONNECTEZ-VOUS",
        cards: {
          card1: [
            "Particuliers",
            "Automobile, Habitations, Retraites, Evacuation Sanitaire, etc.",
          ],
          card2: [
            "Entreprises",
            "Marchandises, Professionnelle, Automobile, Machines, Construction, etc.",
          ],
        },
        choices: {
          title: "Vous avez fait le bon choix",
          item1: {
            h5: "Accès à tous",
            p: "Toutes catégories socio-professionnelles, des garanties Santé adaptées à chaque situation.",
          },
          item2: {
            h5: "Meilleurs protections",
            p: "Votre santé est précieuse, elle mérite la meilleure des couvertures, qualité et performance.",
          },
          item3: {
            h5: "Service Client",
            p: "Contactez-nous ou visitez notre FAQ pour obtenir les réponses à vos questions.",
          },
          buttons: ["ENVOYER DE L'ARGENT", "JE DEMANDE MA CARTE"],
        },
      },
    },
  },
  Support: {
    Faq: {
      title: "Foire aux questions",
      content: {
        action: {
          nextFaq: 'Suivant',
          prevFaq: 'Précédent',
        },
        faq1: {
          question: 'Comment ça marche ?',
          response: {
            p1: 'C\'est une vraie carte de paiement pour dépenser l\'argent dans le monde entier. La carte Visa Sunugal vous accompagne dans tous vos achats et transferts de la vie quotidienne.',
            p2: 'En ligne ou chez les commerçants, au Sénégal comme à l\'étranger, profitez de carte facilement et en toute sécurité. Elle est acceptée chez tous les commerçants Visa et les distributeurs, quelle que soit la monnaie du pays.',
          }
        },
        faq2: {
          question: 'Conditions à remplir pour disposer d\'une carte Sunugal ?',
          response: {
            ul: [
              'Avoir un numéro de téléphone',
              'Avoir une pièce d\'identité valide',
              'Accepter les conditions générales d\'utilisation des services associés à la carte Sunugal.',
            ]
          }
        },
        faq5: {
          question: 'Comment devenir un agent sunugal ?',
          response: {
            p: 'Une procedure simple et une formation adaptée à votre profil.',
            ul: [
              'Compléter le formulaire et fournir les pieces justificatifs.',
              'Signer votre contrat et verser les frais de souscription.',
              'Nous traitons votre demande au plus vite.',
              'Nous activons votre agence et vous remettons votre premier lot de cartes sunugal.',
            ]
          }
        },
        faq3: {
          question: 'Contacter le support',
          response: {
            p1: 'En cas de question ou de problème, les utilisateurs Sunugal peuvent obtenir des réponses en parcourant les FAQ. Il arrive cependant que l\'intervention d\'un membre du support utilisateur soit tout de même nécessaire.',
            p2: {
              title: 'Pour un particulier',
              content: 'Tous les utilisateurs Sunugal peuvent écrire un e-mail à l\'adresse : ',
              a: 'support@sunugal.co',
            },
            p3: {
              title: 'Pour un professionnel',
              content: 'Si l\'utilisateur utilise est professionnel partenaire ou distributeur, il est recommandé de contacter le support par e-mail à l\'adresse : ',
              a: 'supportpro@sunugal.co',
            },
          }
        },
        faq4: {
          question: 'Comment alimenter votre carte ?',
          response: {
            p1: 'Quand l\'utilisateur effectue des opérations avec sa carte sunugal, la carte pioche dans le compte auquel il est lié.',
            p2: 'Si le solde est à sec, c\'est-à-dire si le compte affiche 0, alors les transferts et paiements par carte ne passeront pas.',
            p3: 'L\'utilisateur peut charger sa carte à tout moment.',
          }
        },
        faq6: {
          question: 'Protecttion de vos données personnelles ?',
          response: {
            p1: 'Votre vie privée est essentielle pour nous : vos données personnelles ne seront jamais partagées avec aucune entreprise externe, sauf pour l\'obligation légale et l\'exécution du contrat.',
            p2: 'Par exemple, nous devons respecter la réglementation sur la lutte contre le blanchiment d\'argent (obligation légale) et nous devons transmettre vos coordonnées au fabricant de la carte (exécution du contrat).',
          }
        },
        faq7: {
          question: 'Conseils de sécurité',
          response: {
            p1: 'Afin de sécuriser d\'avantage votre carte, n\'oubliez pas de mettre en œuvre et de suivre les quatre grands principes de sécurité énumérés ci-dessous :',
            p2: 'Ne partagez JAMAIS votre code avec quiconque !',
            p3: 'N\'appelez JAMAIS un numéro d\'une personne prétendant travailler pour Sunugal ou le service client !',
            p4: 'N\'envoyez JAMAIS d\'argent à une personne prétendant être un employé de Sunugal.',
          }
        },
        faq8: {
          question: 'Vos fonds sont en sécurité',
          response: {
            p1: 'Tous les fonds de votre carte Sunugal sont protégés selon les normes de sécurités les plus strictes de Sunugal.',
            p2: 'Vous pouvez dormir sur vos deux oreilles, on s\'occupe de tout.',
          }
        },
        faq9: {
          question: 'Conformité aux exigences de sécurité Visa',
          response: {
            p1: 'La carte de paiement Sunugal Visa est conforme aux plus hautes exigences de sécurité.',
            p2: 'La carte est protégée par la technologie 3D Secure (Verified by Visa) qui rend vos opérations encore plus sûrs.',
          }
        },
        faq10: {
          question: 'Conformité aux exigences de sécurité Visa',
          response: {
            p1: 'La carte de paiement Sunugal Visa est conforme aux plus hautes exigences de sécurité.',
            p2: 'La carte est protégée par la technologie 3D Secure (Verified by Visa) qui rend vos opérations encore plus sûrs.',
          }
        },
      },
    },
    Contact: {
      title: "Contact",
      content: {
        title: "Mutuelle familiale ouverte à tous",
        button1: "CONNECTEZ-VOUS",
        cards: [
          "Accéder aux soins et à des biens médicaux de qualité",
          "Meilleure protection sociale face aux aléas de Santé",
        ],
        choices: {
          title: "Vous avez fait le bon choix",
          item1: {
            h5: "Accès à tous",
            p: "Toutes catégories socio-professionnelles, des garanties Santé adaptées à chaque situation.",
          },
          item2: {
            h5: "Meilleurs protections",
            p: "Votre santé est précieuse, elle mérite la meilleure des couvertures, qualité et performance.",
          },
          item3: {
            h5: "Service Client",
            p: "Contactez-nous ou visitez notre FAQ pour obtenir les réponses à vos questions.",
          },
          buttons: ["ENVOYER DE L'ARGENT", "JE DEMANDE MA CARTE"],
        },
        blockOne: {
          wrapper1: {
            h1: "Vous avez des questions ?",
            p: "Vous pouvez trouver de nombreuses réponses dans la rubrique FAQ.",
          },
          wrapper2: {
            h1: "Vous n'avez pas trouvé la réponse que vous cherchiez ?",
            p: "Vous pouvez nous contacter :",
            element1: ["Par téléphone :", "+221 …"],
            element2: ["Par WhatsApp :", "+221 …"],
            element3: ["Par email :", "info@sunugal.co"],
          },
          wrapper3: {
            h5: "Nous vous répondrons dans les plus brefs délais.",
          },
          wrapper4: {
            p: ["sunuGal.co, Ouest Foire N° 21B", "BP: 1111 Dakar Fann SENEGAL"],
          },
        },
        form: {
          name: {
            label: "Prénom & Nom",
            errors: {
              required: "Ce champ est requis.",
            },
          },
          email: {
            label: "Adresse email",
            errors: {
              required: "Ce champ est requis.",
            },
          },
          subject: {
            label: "Objet du message",
            errors: {
              required: "Ce champ est requis.",
            },
          },
          content: {
            label: "Votre message",
            errors: {
              required: "Ce champ est requis.",
            },
          },
          humanChecker: {
            label: "Résoudre {{operation}}=?",
            errors: {
              required: "Ce champ permet de vérifier que vous êtes un humain.",
            },
          },
          send: "Envoyer",
          notification: {
            successful: "Message envoyé avec succès",
            error: "Une erreur s'est produite lors de l'envoi de votre message",
          },
        },
      },
    },
  },
  About: {
    title: 'A Propos',
    content: {
      wrapper1: {
        h2: 'Créer les services financiers conçus pour vous.',
        p1: 'L\'offre des produits et services sunuGal est tournée vers l\'amélioration du quotidien et de l\'avenir de nos clients.',
        p2: 'Nos produits couvrent tout l\'univers des services dématérialisés :',
        p3: 'Monnaie électronique, paiement par cartes, paiement mobile, transfert de fond, assurances, systèmes d\'abonnement, etc...',
      },
      wrapper2: {
        h2: 'Sécurité au plus haut niveau',
        p1: 'La sécurité est de la plus haute priorité pour Sunugal.',
        p2: 'Nous nous efforçons toujours d\'utiliser les logiciels, les technologies et les systèmes de surveillance qui répondent aux normes les plus élevées, assurant ainsi la protection de votre argent, de vos données et de vos transferts - 24h/24 et 7j/7.',
      },
      wrapper3: {
        h2: 'Nos Valeurs',
        p1: 'Oser la différence',
        p2: 'Excellence',
        p3: 'A votre écoute',
      },
      wrapper4: {
        h2: 'Notre Equipe',
        p1: 'Notre équipe composée de personnes créatives et ambitieuses, toujours en quête d\'excellence.',
        p2: 'Nous sommes passionnés et déterminés, nous partageons un objectif commun: la satisfaction de nos clients et partenaires.',
      },
      wrapper5: {
        p: 'Pour vous accompagner au mieux, nous vous offre une facilité de contact, des informations claires et un dialogue permanent.',
      },
    },
    mission: {
      title: "Mission",
      content:
        "Fournir les service de payement les plus simples et centrés sur la technologie",
    },
    vision: {
      title: "Vision",
      content:
        "Un monde où les paiements sont simples, pratiques, rapides et accessible à tous, surtout, à un prix équitable.",
    },
  },
  Career: {
    title: "Carrières",
    content: {
      card1: [
        "Rejoignez notre équipe d'innovateurs",
        "Nous travaillons ensemble pour l'amélioration du quotidien de nos clients.",
        "Nous utilisons les technologies et des stratégies de pointe pour contribuez à améliorer la façon dont les gens vivent et travaillent.",
      ],
      card2: [
        "Se rencontrer pour dessiner les contours d'une future collaboration :",
        "Contactez-nous :",
        "jobs@sunugal.co",
      ],
    },
  },
  API: {
    FundTransferCardBank: {
      title: "Fonds Carte vert Banque",
      header: "Liste des transferts cartes vers banque",
      filter: {
        userCode: {
          label: "Code utilisateur",
        },
        partnerCode: {
          label: "Code partenaire",
        },
        officeCode: {
          label: "Code bureau",
        },
        startDate: {
          label: "Date de début",
          require: "Date de début requise",
        },
        endDate: {
          label: "Date de fin",
          require: "Date de fin requise",
        },
        load: "Charger",
      },
      table: {
        id: "ID",
        date: "Date",
        partnerCode: "Code du partenaire",
        officeCode: "Code office",
        officeCity: "Ville bureau",
        userCode: "Code utilisateur",
        userIPAddress: "Adresse IP utilisateur",
        requestID: "ID requête",
        responseID: "ID réponse",
        statusCode: "Code statut",
        statusMessage: "Message statut",
      },
    },
    FundTransferCardCard: {
      title: "Fonds Carte vers Carte",
      header: "Liste des transferts cartes vers cartes",
      filter: {
        userCode: {
          label: "Code utilisateur",
        },
        partnerCode: {
          label: "Code partenaire",
        },
        officeCode: {
          label: "Code bureau",
        },
        startDate: {
          label: "Date de début",
          require: "Date de début requise",
        },
        endDate: {
          label: "Date de fin",
          require: "Date de fin requise",
        },
        load: "Charger",
      },
      table: {
        id: "ID",
        date: "Date",
        partnerCode: "Code du partenaire",
        officeCode: "Code office",
        officeCity: "Ville bureau",
        userCode: "Code utilisateur",
        userIPAddress: "Adresse IP utilisateur",
        requestID: "ID requête",
        responseID: "ID réponse",
        statusCode: "Code statut",
        statusMessage: "Message statut",
      },
    },
    FundTransferCardWallet: {
      title: "Fonds Carte vers Portefeuille",
      header: "Liste des transferts cartes vers portefeuille",
      filter: {
        userCode: {
          label: "Code utilisateur",
        },
        partnerCode: {
          label: "Code partenaire",
        },
        officeCode: {
          label: "Code bureau",
        },
        startDate: {
          label: "Date de début",
          require: "Date de début requise",
        },
        endDate: {
          label: "Date de fin",
          require: "Date de fin requise",
        },
        load: "Charger",
      },
      table: {
        id: "ID",
        date: "Date",
        partnerCode: "Code du partenaire",
        officeCode: "Code office",
        officeCity: "Ville bureau",
        userCode: "Code utilisateur",
        userIPAddress: "Adresse IP utilisateur",
        requestID: "ID requête",
        responseID: "ID réponse",
        statusCode: "Code statut",
        statusMessage: "Message statut",
      },
    },
    FundTransferWalletCard: {
      title: "Fonds Portefeuille vers Carte",
      header: "Liste des transferts portefeuille vers cartes",
      filter: {
        userCode: {
          label: "Code utilisateur",
        },
        partnerCode: {
          label: "Code partenaire",
        },
        officeCode: {
          label: "Code bureau",
        },
        startDate: {
          label: "Date de début",
          require: "Date de début requise",
        },
        endDate: {
          label: "Date de fin",
          require: "Date de fin requise",
        },
        load: "Charger",
      },
      table: {
        id: "ID",
        date: "Date",
        partnerCode: "Code du partenaire",
        officeCode: "Code office",
        officeCity: "Ville bureau",
        userCode: "Code utilisateur",
        userIPAddress: "Adresse IP utilisateur",
        requestID: "ID requête",
        responseID: "ID réponse",
        statusCode: "Code statut",
        statusMessage: "Message statut",
      },
    },
    Partner: {
      title: "Partenaire",
      header: {
        title: "Liste des partenaires",
        filter: {
          partnerCode: {
            label: "Code du partenaire",
          },
          load: "Charger",
        },
        create: "Ajouter un partenaire",
      },
      table: {
        id: "ID",
        partnerToken: "Token du partenaire",
        partnerCode: "Code du partenaire",
        name: "Nom",
        status: "Status",
        currencyCode: "Code devise",
        virtualCard: "Carte virtuelle",
        vcLast4Digits: "vc 4 derniers chiffres",
        paymentCard: "Carte payement",
        pcLast4Digits: "pc 4 derniers chiffres",
        update: "Modifier",
        delete: "Supprimer",
      },
      edit: {
        title: "Partenaire",
        form: {
          name: {
            label: "Nom",
            errors: {},
          },
          partnerCode: {
            label: "Code partenaire",
            errors: {},
          },
          partnerToken: {
            label: "Token partenaire",
            errors: {},
          },
          currencyCode: {
            label: "Code de devise",
            errors: {},
          },
          paymentCard: {
            label: "Carte payement",
            errors: {},
          },
          virtualCard: {
            label: "Carte virtuelle",
            errors: {},
          },
          vcLast4Digits: {
            label: "vc 4 derniers chiffres",
            errors: {},
          },
          pcLast4Digits: {
            label: "pc 4 derniers chiffres",
            errors: {},
          },
          status: {
            label: "Status",
            errors: {},
          },
          creationDate: {
            label: "Date création",
            errors: {},
          },
        },
        actions: {
          cancel: "Annuler",
          register: "Enregistrer",
        },
      },
      delete: {
        title: "Suppression",
        message:
          "Etes-vous sûr de vouloir supprimer le partenaire: {{ name }} ?",
        cancel: "Annuler",
        confirm: "Confirmer",
      },
    },
    Gateway: {
      title: "Passerelle",
      header: {
        title: "Liste des passerelles",
        filter: {
          environment: {
            label: "Environnement",
          },
          status: {
            label: "Status",
          },
          load: "Charger",
        },
        create: "Ajouter une passerelle",
      },
      table: {
        id: "ID",
        date: "Date",
        url: "URL",
        environment: "Environnement",
        securityToken: "Token de sécurité",
        subcompany: "Sous-société",
        status: "Status",
        update: "Modifier",
        delete: "Supprimer",
      },
      edit: {
        title: "Passerelle",
        form: {
          id: {
            label: "ID",
            errors: {},
          },
          date: {
            label: "Date",
            errors: {},
          },
          url: {
            label: "URL",
            errors: {},
          },
          environment: {
            label: "environnement",
            errors: {},
          },
          securityToken: {
            label: "Token de sécurité",
            errors: {},
          },
          subcompany: {
            label: "Sous-société",
            errors: {},
          },
          status: {
            label: "Status",
            errors: {},
          },
        },
        actions: {
          cancel: "Annuler",
          register: "Enregistrer",
        },
      },
      delete: {
        title: "Suppression",
        message:
          "Etes-vous sûr de vouloir supprimer le passerelle: {{ name }} ?",
        cancel: "Annuler",
        confirm: "Confirmer",
      },
    },
    Country: {
      title: "Pays",
      header: "Liste des pays",
      filter: {
        country: {
          label: "Nom du pays",
        },
        load: "Charger",
      },
      table: {
        id: "ID",
        country: "Pays",
        alpha2code: "Code alpha-2",
        alpha3code: "Code alpha-3",
        numeric: "Numérique",
      },
    },
    State: {
      title: "Ville",
      header: "Liste des villes",
      filter: {
        countryCode: {
          label: "Code du pays",
        },
        load: "Charger",
      },
      table: {
        id: "ID",
        state: "Ville",
        statedesc: "Description",
        country: "Pays",
      },
    },
    Balance: {
      title: "Balance",
      header: "Liste des balances",
      filter: {
        partnerCode: {
          label: "Code partenaire",
        },
        officeCode: {
          label: "Code bureau",
        },
        userCode: {
          label: "Code utilisateur",
        },
        startDate: {
          label: "Date de début",
          require: "Date de début requise",
        },
        endDate: {
          label: "Date de fin",
          require: "Date de fin requise",
        },
        load: "Charger",
      },
      table: {
        id: "ID",
        date: "Date",
        partnerCode: "Code du partenaire",
        officeCode: "Code office",
        officeCity: "Ville bureau",
        userCode: "Code utilisateur",
        requestID: "ID requête",
        responseID: "ID réponse",
        statusCode: "Code statut",
        statusMessage: "Message statut",
      },
    },
  },
  Layout: {
    Sidebar: {
      main: {
        title: "Menu principal",
        dashboard: "Dashboard",
        fundTransfer: {
          title: "Transfert de fonds",
          fundtransfercardtobank: "Transfert de fonds carte vers banque",
          fundtransfercardtocard: "Transfert de fonds carte vers carte",
          fundtransfercardtowallet:
            "Transfert de fonds carte vers portefeuille",
          fundtransferwallettocard:
            "Transfert de fonds portefeuille vers carte",
        },
        balance: "Balance",
        partner: "Partenaire",
        gateway: "Passerelle",
        location: {
          title: "Localisation",
          country: "Pays",
          state: "Ville",
        },
      },
    },
    Navbar: "",
  },
  Shared: {
    Accounts: {
      login: {
        title: "Connexion",
        subtitle: "Connectez-vous avec vos identifiants",
        form: {
          first: {
            username: {
              label: "Nom d'utilisateur",
              errors: {
                required: "Le nom d'utilisateur est requis",
              },
            },
            password: {
              label: "Mot de passe",
              errors: {
                required: "Le mot de passe est requis",
              },
            },
            login: "Se connecter",
          },
          second: {
            code: {
              label: "Code de vérification",
              errors: {
                required: "Le code de vérification est requis",
              },
            },
            checkCode: "Valider le code",
          },
          forgotPassword: "Mot de passe oublié",
        },
        notification: {
          successful: "Connecté avec succès",
          badCredentials: "Identifiant ou mot de passe incorrect",
          error: "Erreur lors de la connexion",
        },
      },
    },
    Pagination: {
      getRangeLabel: "sur",
      itemsPerPageLabel: "Eléments par page",
      firstPageLabel: "Première page",
      lastPageLabel: "Dernière page",
      nextPageLabel: "Page suivante",
      previousPageLabel: "Page précédente",
    },
  },
  Legal: {
    title: "Mentions légales",
    wrapper1: {
      title: "Qui sommes-nous ?",
      p: 'Sunugal.co, société sénégalaise basée à Dakar, conçoit, réalise et exploite des programmes de cartes bancaires prépayées et moyens de paiement, en partenariat avec des institutions financières, des processeurs bancaires et des fabricants de cartes leader dans leur domaine.',
    },
    wrapper2: {
      title: "Droit d'auteur",
      p: 'Le site Sunugal.co constitue une création protégée par le droit d\'auteur. Tous les éléments de toute nature (images fixes, images animées, bases de données, marques, illustrations, logos, dessins, modèles, mise en page, documents téléchargeables…) contenus dans ce site sont protégés en tant qu\'œuvre de l\'esprit par la législation luxembourgeoise et internationale sur le droit d\'auteur et la propriété intellectuelle. Toute copie, adaptation, traduction, arrangement, communication au public, location et autre exploitation, modification de tout ou partie de ce site sous quelle que forme et par quelque moyen que ce soit, électronique, mécanique ou autre, est strictement interdit sauf accord préalable et écrit de Tango. Toute infraction à ces droits peut entraîner des poursuites civiles et/ou pénales.',
    },
    wrapper3: {
      title: 'Siège social :',
      p1: 'Sunugal.co',
      p2: 'N°21 B, Ouest-Foire Dakar Sénégal',
      p3: 'Contact par e-Mail : contact@sunugal.co',
    },
    wrapper4: {
      title: 'CONDITIONS GENERALES D\'UTILISATION DU SITE',
      subwrap1: {
        title: '1. Objet',
        p: 'Les présentes conditions générales d\'utilisation (ci-après, les « Conditions Générales d\'Utilisation ») ont pour objet d\'encadrer l\'utilisation du site internet accessible à l\'adresse www.sunugal.co (ci-après, le « Site ») édité par la société Sunugal.co (ci-après, « Sunugal.co »), sans préjudice des dispositions figurant dans les contrats conclus entre Sunugal.co et ses clients. Les usagers du site déclarent avoir pris connaissance des Conditions Générales d\'Utilisation, les avoir comprises et les avoir acceptées sans réserve.',
      },
      subwrap2: {
        title: '2. Propriété intellectuelle',
        p1: 'Les usagers du Site s\'engagent à ne pas réutiliser les informations issues du Site, ni à reproduire la page d\'accueil ou toute autre page du Site. Les usagers du Site s\'engagent à respecter l\'ensemble des droits de propriété intellectuelle de Sunugal.co. A ce titre, les usagers du Site s\'engagent à ne pas copier, modifier, louer, vendre, distribuer tout ou partie des informations présentes sur le Site, y compris la marque',
        p2: 'Sunugal.co ou toute autre marque déposée par Sunugal.co, les noms commerciaux, logos, noms de domaine ou tout autre signe distinctif appartenant à Sunugal.co ou figurant sur le Site. Les usagers du Site s\'engagent enfin à ne pas accéder ou ne pas tenter d\'accéder au Site par tout moyen autre que l\'interface du Site fournie par Sunugal.co, ainsi qu\'à n\'entreprendre aucune activité de nature à entraver ou à perturber la fourniture des services du Site par Sunugal.co.',
      },
      subwrap3: {
        title: '3. Fonctionnement et disponibilité du Site',
        p: 'Sunugal.co s\'efforcera, dans la mesure du possible, de maintenir le Site accessible 7 jours sur 7 et 24 heures sur 24. Néanmoins, l\'accès au Site pourra être temporairement suspendu, en raison d\'opérations techniques de maintenance, de migrations ou de mises à jour, en raison de pannes ou de contraintes liées au fonctionnement d\'internet. Il est rappelé que la transmission des données sur internet ne bénéficie que d\'une fiabilité relative. Les réseaux sur lesquels circulent les données présentent des caractéristiques et capacités diverses et sont susceptibles d\'être saturés à certaines heures de la journée, ce qui est de nature à affecter leur délais de téléchargement ainsi que leur accessibilité. Sunugal.co ne peut pas non plus garantir l\'absence d\'interception de données.',
      },
      subwrap4: {
        title: '4. Modification des Conditions Générales d\'Utilisation',
        p1: 'Sunugal.co pourra être amené à modifier les présentes Conditions Générales d\'Utilisation afin de s\'adapter à l\'environnement technologique et commercial et afin de se conformer à la réglementation en vigueur. Les usagers du Site seront informés de toute modification apportée aux Conditions Générales d\'Utilisation par le biais d\'une mention spécifique sur la page d\'accueil du Site.',
        p2: 'Les usagers du Site sont invités à prendre connaissance des Conditions Générales d’Utilisation à chaque nouvelle visite du Site.',
      },
      subwrap5: {
        title: '5. Loi applicable',
        p: 'Les présentes Conditions Générales d’Utilisation sont régies par le droit sénégalais.',
      },
    },
  },
  Confidentiality: {
    title: 'Déclaration de confidentialité',
    wrapper1: {
      p: 'Chez Sunugal.co nous respectons votre vie privée. La protection de la vie privée est importante lorsque nous proposons nos services chez nos partenaires et distributeurs, où vous pouvez acheter et recharger vos cartes de crédit prépayées faciles à commander et immédiatement disponibles.',
    },
    wrapper2: {
      title: 'Pourquoi nous collectons vos données',
      p1: '« Le client d’abord » est l’une de nos valeurs clés. Pour tenir cette promesse, nous devons en savoir plus sur vous, tout en respectant votre vie privée.',
      p2: 'Pour exécuter notre contrat de vente, nous utilisons des données que vous fournissez lorsque vous utilisez nos services ou lorsque vous nous contactez, comme votre nom, votre adresse e-mail, votre adresse et votre numéro de téléphone. Nos services d’assistance client utilisent également ces données lorsque vous avez une question à propos de votre opérations.',
      p3: 'Nous possédons un système automatisé qui peut utiliser vos données personnelles pour empêcher et combattre la fraude.',
      p4: 'Nous pouvons utiliser vos données personnelles pour améliorer nos services et accroître la qualité de nos services, et également à des fins de marketing direct qui peut inclure l’analyse, la segmentation en profils ainsi que le développement de notre clientèle en ligne. En comprenant mieux vos points d\'intérêt et vos préférences, nous pouvons vous offrir une expérience personnelle et authentique, qui comprend des communications en matière de marketing et de transactions que vous recevez de notre part, ainsi que d’autres notifications et publicités que vous voyez dans nos plateformes en ligne.',
    },
    wrapper3: {
      title: 'Durée de conservation des données',
      p: 'Nous ne conservons vos données que le temps nécessaire pour la prestation de nos services, sauf si nous sommes légalement obligés de les conserver pour une période plus longue.',
    },
    wrapper4: {
      title: 'Tiers',
      p1: 'Afin de traiter vos opérations, il est possible que nous soyons obligés de partager vos données personnelles avec des tiers, comme les organismes de paiement et autres sociétés pour exécuter nos services. Soyez rassuré, nous ne vendrons jamais vos données à quiconque.',
      p2: 'Dans certains cas, nous serons obligés de transférer vos données personnelles à des sociétés en dehors de l’Espace UEMOA (Union Economique et Monétaire Ouest Africaine).',
      p3: 'La sécurité de vos données reste, évidemment, une priorité. Nous exigerons toujours une décision d’adéquation de la Commission de Protection des Données Personnelles.',
    },
    wrapper5: {
      title: 'Cookies',
      p: 'Nos sites Web utilisent des cookies fonctionnels, analytiques et de suivi. Nous avons besoin de cookies fonctionnels pour que le site Web fonctionne correctement et pour traiter vos achats. Nous déposons les autres cookies pour vous proposer des pages Web sur mesure. Certains de ces cookies sont déposés par des tiers externes. Vous trouverez plus d’informations à ce sujet dans notre Déclaration sur les cookies.',
    },
    wrapper6: {
      title: 'Vos droits',
      p1: 'Si vous ne souhaitez plus recevoir d\'e-mail de notre part, vous pouvez vous opposer au traitement de vos données personnelles et vous désinscrire. Pour ce faire, vous pouvez utiliser le lien de désinscription dans l’e-mail.',
      p2: 'Vous avez le droit de nous demander de vérifier, corriger ou supprimer toutes les données personnelles que nous avons enregistrées sur vous, ainsi que le droit de vous opposer au traitement ou de le limiter, et de transférer vos données dans un format exploitable par une machine. Les demandes officielles peuvent être envoyées à serviceclient@sunugal.co. Dans ces cas, nous pouvons vous demander une copie de votre pièce d’identité ou votre passeport.',
    },
    wrapper7: {
      title: 'Des questions ?',
      p1: 'Si vous pensez que nous utilisons vos données personnelles incorrectement, ou que nous ne répondons pas convenablement à vos questions, veuillez contacter nos services client à serviceclient@sunugal.co.',
      p2: 'Nous nous efforcerons de vous répondre dans les meilleurs délais.',
    }
  },
  Admin: {
    User: {
      title: "Utilisateur",
      header: "Utilisateurs",
      table: {
        firstname: "Prénom",
        lastname: "Nom",
        username: "Nom d'utilisateur",
        email: "Email",
        roles: "Roles",
      },
      menu: {
        update: "Modifier",
        block: "Bloquer",
        unblock: "Débloquer",
        delete: "Supprimer",
      },
    },
  },
};
