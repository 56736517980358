import { map } from 'rxjs/operators';
import { User } from './../user/user.model';
import { TokenStorageService } from './token-storage.service';
import { UserService } from './../user/user.service';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private language = 'fr';
    private user: User;
    private authentificated = false;
    private authentificationState = new Subject<any>();
    private languageState = new Subject<any>();

    constructor(
        private userService: UserService,
        private tokenService: TokenStorageService
    ) {
        this.user = this.tokenService.getUser();
    }

    /**
     * identity must content username, email, roles and accessToken
     *
     * @param identity
     */
    authentificate(identity) {
        this.user = new User();

        if (identity) {
            this.user.id = identity.id;
            this.user.email = identity.email;
            this.user.username = identity.username;
            this.user.roles = identity.roles;
            
            // /** On enrégister le user token dans la session. */
            this.tokenService.saveUser(this.user);
            /** On enrégister le user et le token dans la session. */
            this.tokenService.saveToken(identity.accessToken);
        }

        this.authentificated = identity !== null;
        this.authentificationState.next(this.user);
    }

    /** Récupération des informations de l'utilisateur connecté. */
    identity(): Promise<any> {
        this.user = this.tokenService.getUser();
        if (this.user) {
            /** S'il manque le prénom ou le nom dans les informations du current user */
            const incomplete = !this.user.firstname && !this.user.lastname;
            if (incomplete) {
                /** Récupération des informations complètes de l'utilisateur. */
                return this.userService.find(this.user.id).pipe(map(e => e.body)).toPromise()
                    .then((user: User) => {
                        /** On complète les informations des prénom et nom */
                        this.user.firstname = user.firstname;
                        this.user.lastname = user.lastname;
                        this.tokenService.saveUser(this.user);
                        this.authentificationState.next(this.user);
                        return this.user;
                    })
                    .catch(error => {
                        this.user = null;
                        console.log(error);
                        this.authentificationState.next(this.user);
                        return null;
                    });
            } else {
                return Promise.resolve(this.user);
            }
        }

        return Promise.resolve(null);
    }

    logout() {
        this.tokenService.signOut();
        this.authentificate(null);
    }

    hasAnyRole(roles: string[]): Promise<boolean> {
        return Promise.resolve(this.getHasAnyRole(roles))
    }

    getHasAnyRole(roles: string[]): boolean {
        this.user = this.tokenService.getUser();
        if (!this.isAuthentificate || !this.user || !this.user.roles) {

            return false;
        }

        for (let i = 0; i < this.user.roles.length; i++) {
            if (roles.findIndex((element) => element === this.user.roles[i]) !== -1) {
                return true;
            }
        }
        return false;
    }

    getAuthentificationState(): Observable<any> {
        return this.authentificationState.asObservable();
    }

    update(user: User) {
        this.tokenService.saveUser(user);
    }

    getLanguageState(): Observable<any> {
        return this.languageState.asObservable();
    }

    isAuthentificate(): boolean {
        if (!this.authentificated) {
            this.authentificated = (this.tokenService.getUser()) ? true : false;
        }
        return this.authentificated;
    }

    getLanguage(): string {
        return this.language;
    }

    setLanguage(language: string) {
        this.language = language;
        this.languageState.next(language);
    }
}
