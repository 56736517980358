import { IPage } from 'src/app/shared/models/page.model';
import { createRequestOption } from './request-util';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { configDev } from './../../environments/configurations/config.dev';
import { Observable } from 'rxjs';

export type EntityResponseType<T> = Observable<HttpResponse<T>>;
export type EntityArrayResponseType<T> = Observable<HttpResponse<T[]>>;

/**
 * @author yusufaye
 * @date 16/04/2021
 * @update 16/04/2021
 */
export abstract class BaseApiService<T> {
    protected http: HttpClient;
    protected readonly entityName: string;
    protected readonly baseUrl = `${configDev.server.host}:${configDev.server.port}`;
    protected readonly url: string; // url to contact service

    /**
     *
     * @param entityName the name of real entity handled
     */
    constructor(entityName: string) {
        this.entityName = entityName;
        this.url = `${configDev.server.host}:${configDev.server.port}/${entityName}`;
    }

    /**
     * save entity
     */
    public save(entity: T): EntityResponseType<T> {
        return this.http.post<T>(`${this.url}`, entity, { observe: 'response' });
    }

    /**
     * update entity
     */
    public update(entity: T): EntityResponseType<T> {
        return this.http.put<T>(`${this.url}`, entity, { observe: 'response' });
    }

    /**
     * find entity
     */
    public find(id: number | string): EntityResponseType<T> {
        return this.http.get<T>(`${this.url}/${id}`, { observe: 'response' });
    }

    /**
     * query entity
     */
    public query(req?: any): EntityArrayResponseType<T> {
      const options = createRequestOption(req);
      return this.http.get<T[]>(`${this.url}`, { params: options, observe: 'response' });
    }

    /**
     * delete entity
     */
    public delete(id: number | string): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.url}/${id}`, { observe: 'response' });
    }

    /**
     * 
     * @param data 
     * @param req 
     * @returns 
     */
    page(data: any, req?: any): EntityResponseType<IPage<T>> {
      const options = createRequestOption(req);
      return this.http.post<IPage<T>>(`${this.url}`, data, { params: options, observe: 'response' });
    }

}
