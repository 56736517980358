import { NotificationSnackBarComponent } from './notification-snack-bar.component';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [NotificationSnackBarComponent],
  entryComponents: [NotificationSnackBarComponent],
  imports: [MatSnackBarModule],
})
export class NotificationModule {}
