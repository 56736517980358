import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import fr from './../../assets/i18n/fr';
import en from './../../assets/i18n/en';

@Injectable({ providedIn: 'root' })
export class Translations extends GenericClass<typeof fr>() {
    constructor() {
        super();
        Object.assign(this, transformObjectToPath('', fr));
    }
}

export function GenericClass<Props>(): new () => Props {
    return class { } as any;
}

function concatIfExistsPath(path: string, suffix: string): string {
    return path ? `${path}.${suffix}` : suffix;
}

function transformObjectToPath<T extends object | string>(
    suffix: string,
    objectToTransformOrEndOfPath: T,
    path = ''
): T {
    return typeof objectToTransformOrEndOfPath === 'object'
        ? Object.entries(objectToTransformOrEndOfPath)
            .reduce(
                (objectToTransform, [key, value]) => {
                    objectToTransform[key] = transformObjectToPath(
                        key,
                        value,
                        concatIfExistsPath(path, suffix),
                    );
                    return objectToTransform;
                },
                {} as T)
        : (concatIfExistsPath(path, suffix) as T);
}

export class WebpackTranslateLoader implements TranslateLoader {
    getTranslation(lang: string): Observable<any> {
        return from(import(`./../../assets/i18n/${lang}.ts`)).pipe(pluck('default'));
    }
}
