export interface IUser {
    id?: number;
    firstname?: string;
    lastname?: string;
    username?: string;
    email?: string;
    password?: string;
    status?: string;
    roles?: string[];
}

export class User implements IUser {
    constructor(
        public id?: number,
        public firstname?: string,
        public lastname?: string,
        public username?: string,
        public email?: string,
        public password?: string,
        public status?: string,
        public roles?: string[],
    ) { }
}