import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MatPaginationIntlService extends MatPaginatorIntl {

  constructor(private translateService: TranslateService) {
    super();

    // React whenever the language is changed
    this.translateService.onLangChange.subscribe((e) => this.translateLabels());

    // Initialize the translations once at construction time
    this.translateLabels();
  }

  public getRangeLabel = (page: number, pageSize: number, length: number): string => {
    const of = this.translateService ? this.translateService.instant('Shared.Pagination.getRangeLabel') : 'of';

    if (length === 0 || pageSize === 0) {
      return `0 ${of} 0`;
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize > length ? (Math.ceil(length / pageSize) - 1) * pageSize : page * pageSize;

    const endIndex = Math.min(startIndex + pageSize, length);
    return `${startIndex + 1} - ${endIndex} ${of} ${length}`;
  }

  translateLabels(): void {
    this.itemsPerPageLabel = this.translateService.instant('Shared.Pagination.itemsPerPageLabel');
    this.firstPageLabel = this.translateService.instant('Shared.Pagination.firstPageLabel');
    this.lastPageLabel = this.translateService.instant('Shared.Pagination.lastPageLabel');
    this.nextPageLabel = this.translateService.instant('Shared.Pagination.nextPageLabel');
    this.previousPageLabel = this.translateService.instant('Shared.Pagination.previousPageLabel');
    this.changes.next(); // Fire a change event to make sure that the labels are refreshed
  }
}