import { EntityResponseType, EntityArrayResponseType } from 'src/app/shared/base-api.service';
import { IUser } from './user.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseApiService } from 'src/app/shared/base-api.service';

const endPoint = 'adm/v1';

/**
 * @author yusufaye
 * @date created date
 * @description Handle API <name-of-api>
 */
@Injectable({ providedIn: 'root' })
export class UserService extends BaseApiService<IUser> {
  private urlNoAuth = '/api/v1/no-auth/user';

  constructor(private httpClient: HttpClient) {
    super(endPoint);
    this.http = this.httpClient;
  }

  /**
   * update entity
   */
  public updateByCurrent(entity: IUser): EntityResponseType<IUser> {
    return this.http.put<IUser>(`${this.url}`, entity, { observe: 'response' });
  }

  signin(credentials: { username: string, password: string }): EntityResponseType<IUser> {
    return this.httpClient.post<IUser>(`${this.url}/no-auth/user/signin`, credentials, { observe: 'response' });
  }

  find(id: number): EntityResponseType<IUser> {
    return this.httpClient.get<IUser>(`${this.url}/${id}`, { observe: 'response' });
  }

  query(header?: any): EntityArrayResponseType<IUser> {
    return this.httpClient.get<IUser[]>(this.url, { headers: header, observe: 'response' });
  }

  completeRegistration(data: { password: string, token: string }): Observable<any> {
    return this.httpClient.post(`${this.urlNoAuth}/confirmation`, data);
  }

  resetPassword(email: string): Observable<any> {
    return this.httpClient.post(`${this.urlNoAuth}/reset-password/${email}`, null);
  }

  changePassword(data: { username: string, oldPassword: string, newPassword: string }): Observable<any> {
    return this.httpClient.put(`${this.url}/password`, data);
  }

  block(id: number): Observable<any> {
    return this.httpClient.put<any>(`${this.url}/block/${id}`, null);
  }

  unblock(id: number): Observable<any> {
    return this.httpClient.put<any>(`${this.url}/unblock/${id}`, null);
  }

  findAllRoles(): Observable<HttpResponse<any>> {
    return this.httpClient.get(`${this.url}/roles`, { observe: 'response' });
  }
}
